function HandleTimeline() {
  const data = [
    [
      {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
       <g fill="currentColor">
          <path
             d="M8 9a.75.75 0 0 0 0 1.5h8A.75.75 0 0 0 16 9zm3.25 4.75A.75.75 0 0 1 12 13h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75M10 17a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5z" />
          <path
             d="M15.986 4a2.25 2.25 0 0 0-2.236-2h-3.5a2.25 2.25 0 0 0-2.236 2H6.25A2.25 2.25 0 0 0 4 6.25v13.5A2.25 2.25 0 0 0 6.25 22h11.5A2.25 2.25 0 0 0 20 19.75V6.25A2.25 2.25 0 0 0 17.75 4zm.009.096L16 4.25q0-.078-.005-.154M10.25 6.5h3.5c.78 0 1.467-.397 1.871-1h2.129a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H6.25a.75.75 0 0 1-.75-.75V6.25a.75.75 0 0 1 .75-.75h2.129c.404.603 1.091 1 1.871 1m0-3h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5" />
       </g>
    </svg>`,
        time: `15:49 13-07-2024`,
        title: `Đặt hàng thành công`,
        description: `Đơn hàng đã được đặt`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đang chờ xác nhận từ hệ thống`,
      },
    ],
    [
      {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
       <path fill="currentColor"
          d="M10.591 2.513a3.75 3.75 0 0 1 2.818 0l7.498 3.04A1.75 1.75 0 0 1 22 7.175v9.653a1.75 1.75 0 0 1-1.093 1.621l-7.498 3.04a3.75 3.75 0 0 1-2.818 0l-7.498-3.04A1.75 1.75 0 0 1 2 16.827V7.176a1.75 1.75 0 0 1 1.093-1.622zm2.254 1.39a2.25 2.25 0 0 0-1.69 0L9.24 4.68l7.527 2.927l2.669-1.03zm1.846 4.505L7.215 5.5L4.59 6.564l7.411 2.882zM3.5 16.828a.25.25 0 0 0 .156.231l7.499 3.04q.047.02.095.036v-9.371L3.5 7.75zm9.345 3.271l7.499-3.04a.25.25 0 0 0 .156-.232V7.774l-7.75 2.992v9.37z" />
    </svg>`,
        time: `15:49 13-07-2024`,
        title: `Đang chờ xác nhận`,
        description: `Đơn hàng đã được xác nhận thành công`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đã được đóng gói`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đã được giao cho đơn vị vận chuyển`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đã được giao đến kho Thái Lan`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đã rời kho Thái Lan`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đang vận chuyển về Việt Nam`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đã đến Việt Nam`,
      },
      {
        time: `15:49 13-07-2024`,
        description: `Đơn hàng đã đến kho phân loại`,
      },
    ],
    [
      {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
       <path fill="currentColor"
          d="M4.25 3A2.25 2.25 0 0 0 2 5.25v12a2.25 2.25 0 0 0 2.04 2.24a3 3 0 0 0 5.919.01h2.082a3 3 0 0 0 5.918 0h1.791A2.25 2.25 0 0 0 22 17.25v-5.413a2.25 2.25 0 0 0-.217-.963l-1.699-3.587A2.25 2.25 0 0 0 18.051 6H16.5v-.75A2.25 2.25 0 0 0 14.25 3zm13.58 15a3 3 0 0 0-1.33-1.599V12.5h4v3h-1.25a.75.75 0 1 0 0 1.5h1.25v.25a.75.75 0 0 1-.75.75zM15 16a3 3 0 0 0-2.83 2H9.83a3.001 3.001 0 0 0-5.658-.004a.75.75 0 0 1-.672-.746v-12a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 .75.75zm1.5-8.5h1.55a.75.75 0 0 1 .679.429L20.183 11H16.5zM7 20.5a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3m9.5-1.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0" />
    </svg>`,
        time: `15:49 13-07-2024`,
        title: `Đang vận chuyển`,
        description: `Đơn hàng sẽ được giao tới sớm, vui lòng chú ý điện thoại`,
      },
    ],
    [
      {
        icon: ` <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
    </svg>`,
        time: `15:49 13-07-2024`,
        title: `Giao hàng`,
        description: `Đơn hàng dự kiến sẽ được giao vào ngày 02/08/2024`,
      },
    ],
  ];

  const $timelineContainer = $("#timeline");

  $.each(data, function (index, group) {
    const $section = $("<section>").addClass("timeline-group");

    $.each(group, function (index, item) {
      const $div = $("<div>").addClass("timeline-item");

      if (item.icon && item.title) {
        // Create icon container
        const $iconDiv = $("<div>").addClass("timeline-icon").html(item.icon);
        $div.append($iconDiv);

        // Create time element
        const $timeElement = $("<time>").text(item.time);
        $div.append($timeElement);

        // Create text container
        const $textDiv = $("<div>")
          .addClass("timeline-text")
          .html(
            `<strong>${item.title}</strong><span>${item.description}</span>`
          );
        $div.append($textDiv);
      } else {
        // Create dot container
        const $dotDiv = $("<div>").addClass("timeline-dot");
        $div.append($dotDiv);

        // Create time element
        const $timeElement = $("<time>").text(item.time);
        $div.append($timeElement);

        // Create text container
        const $textDiv = $("<div>")
          .addClass("timeline-text")
          .html(`<span>${item.description}</span>`);
        $div.append($textDiv);
      }

      $section.append($div);
    });

    $timelineContainer.append($section);
  });
}

export default HandleTimeline;